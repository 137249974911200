import React  from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  // const data = useStaticQuery(graphql`
  //     query {
  //
  //     }
  // `)

  return (
    <Layout>
      <SEO title={"Contatti"} />
      <div className={"fixed-bottom"}>
        <p className="text-center">
          Via Fermi 14, Sagrado (GO) 34078
        </p>
      </div>
      <div className="container">
        <div className="row justify-content-center align-content-center vh-100 p-2">
          <main className="col-12 col-md-8 text-center">
            <h1>dr. Giorgio Pelos</h1>
            <p className="lead">Otorinolaringoiatra, audiologo</p>
            <ul className="list-unstyled">
              <li><a href="tel:048199592" className="btn btn-outline-danger fw-bold m-1">Chiama +39 0481 99592</a></li>
              <li><a href="tel:3289696632" className="btn btn-outline-danger fw-bold m-1">Chiama +39 328 9696632</a></li>
              <li><a href="mailto:segreteria.sagrado@gmail.com" className="btn btn-outline-danger fw-bold m-1">Scrivi una mail segreteria.sagrado@gmail.com</a></li>
            </ul>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
